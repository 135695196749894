

































































































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch } from "@/store";

export default Vue.extend({
  data() {
    return {
      collapsed: false
    };
  },
  computed: {
    ...mapState("app", {
      account: "account"
    }),
    selectedKeys(): string[] {
      return [
        this.$paths.products,
        this.$paths.orders,
        this.$paths.notifications,
        this.$paths.couponActivity,
        this.$paths.couponSend,
        this.$paths.userCoupon,
        this.$paths.numbers,
        this.$paths.snCode,
        this.$paths.warehouseOrder,
        this.$paths.keyboardCustom,
        this.$paths.draw,
        this.$paths.settings,
        this.$paths.www
      ].filter(path => this.$route.path.includes(path));
    }
  },
  methods: {
    signOut() {
      dispatch.appSignOut().then(() => {
        window.location.href = "/";
      });
    }
  },
  watch: {
    $route() {
      if (
        this.account.role !== "admin" &&
        this.account.role !== "mall_admin" &&
        this.$route.path !== this.$paths.auth
      ) {
        this.$router.replace(this.$paths.auth);
      }
    }
  }
});
